var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',[_c('loading-flux',{attrs:{"value":_vm.loading}}),_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-md-8 col-lg-6 col-xl-5"},[_c('div',{staticClass:"card overflow-hidden"},[_c('div',{staticClass:"bg-soft bg-primary",staticStyle:{"background-image":"url(https://fluxsolar.cl/app/assets/img/bg01d.jpg)"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-7"},[_c('div',{staticClass:"p-4",staticStyle:{"color":"#fff"}},[_c('h5',{staticStyle:{"color":"#fff"}},[_vm._v(" Cambiar Contraseña ")]),_c('p',[_vm._v(" Cambiar contraseña Administrador de proyectos. ")])])]),_c('div',{staticClass:"col-5 align-self-center"},[_c('img',{staticClass:"img-fluid",attrs:{"src":"/imagenes/logo-light.png","alt":""}})])])]),_c('div',{staticClass:"card-body pt-0"},[_c('div',[_c('span',[_c('div',{staticClass:"avatar-md profile-user-wid mb-4"},[_c('span',{staticClass:"avatar-title rounded-circle bg-light"},[_c('img',{attrs:{"src":require("@/assets/images/logo.svg"),"alt":"","height":"34"}})])])])]),_c('div',{staticClass:"p-2"},[_c('b-alert',{staticClass:"mb-4",attrs:{"variant":"danger","dismissible":""},model:{value:(_vm.isResetError),callback:function ($$v) {_vm.isResetError=$$v},expression:"isResetError"}},[_vm._v(_vm._s(_vm.error))]),_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.tryToReset.apply(null, arguments)}}},[_c('div',{staticClass:"mb-3"},[_c('label',{attrs:{"for":"nuevacontrasena"}},[_vm._v("Nueva Contraseña")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.form.nuevaContrasena.$model),expression:"$v.form.nuevaContrasena.$model"}],staticClass:"form-control",class:{
                                        'is-invalid':
                                            _vm.submitted &&
                                            _vm.$v.form.nuevaContrasena.$error,
                                    },attrs:{"type":"text","id":"nuevacontrasena","placeholder":"Contraseña"},domProps:{"value":(_vm.$v.form.nuevaContrasena.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.form.nuevaContrasena, "$model", $event.target.value)}}}),(
                                        _vm.submitted &&
                                            _vm.$v.form.nuevaContrasena.$error
                                    )?_c('div',{staticClass:"invalid-feedback"},[(
                                            !_vm.$v.form.nuevaContrasena
                                                .required
                                        )?_c('span',[_vm._v("Contraseña Requerida.")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"mb-3"},[_c('label',{attrs:{"for":"repetirContrasena"}},[_vm._v("Repetir Contraseña")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.form.repetirContrasena.$model),expression:"$v.form.repetirContrasena.$model"}],staticClass:"form-control",class:{
                                        'is-invalid':
                                            _vm.submitted &&
                                            _vm.$v.form.repetirContrasena
                                                .$error,
                                    },attrs:{"type":"text","id":"repetirContrasena","placeholder":"Contraseña"},domProps:{"value":(_vm.$v.form.repetirContrasena.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.form.repetirContrasena, "$model", $event.target.value)}}}),(
                                        _vm.submitted &&
                                            _vm.$v.form.repetirContrasena.$error
                                    )?_c('div',{staticClass:"invalid-feedback"},[(
                                            !_vm.$v.form.repetirContrasena
                                                .required
                                        )?_c('span',[_vm._v("Contraseña Requerida.")]):_vm._e(),(
                                            !_vm.$v.form.repetirContrasena
                                                .sameAsPassword
                                        )?_c('span',[_vm._v("La contraseña no coincide.")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"mb-3 row mb-0"},[_c('div',{staticClass:"col-12 text-end"},[_c('button',{staticClass:"btn btn-primary w-md",attrs:{"type":"submit"}},[_vm._v(" Cambiar ")])])])])],1)])]),_c('div',{staticClass:"mt-5 text-center"},[_c('p',[_vm._v(" La recuerdas ? "),_c('router-link',{staticClass:"fw-medium text-primary",attrs:{"to":"/login"}},[_vm._v("Iniciar Sesión")])],1),_c('p',[_vm._v(" © "+_vm._s(new Date().getFullYear())+" FluxSolar "),_c('i',{staticClass:"mdi mdi-heart text-danger"})])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }